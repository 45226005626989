import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FloatingMenuComponent } from "../../components/floating-menu/floating-menu.component";
import { HeaderComponent } from "../../components/header/header.component";
import { BackButtonComponent } from "../../components/back-button/back-button.component";
import { FormsModule } from "@angular/forms";
import { StyleFormatterPipe } from "../../pipe/style-formatter.pipe";
import { SelectDropDownModule } from 'ngx-select-dropdown';

@NgModule({
  declarations: [HeaderComponent, FloatingMenuComponent, BackButtonComponent,StyleFormatterPipe],
  exports: [HeaderComponent, FloatingMenuComponent, BackButtonComponent,StyleFormatterPipe],
  imports: [CommonModule, FormsModule,SelectDropDownModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
