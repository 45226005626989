import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { throwError as observableThrowError, Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { Router } from '@angular/router';
import { ToastService } from "../shared/services/toast.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  token = localStorage.getItem("token");
  constructor(private router: Router,private toast:ToastService) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    req = req.clone({
      setHeaders: {
        // 'Content-Type': 'application/json; charset=utf-8',
        // Accept: 'application/json',
        Authorization: `token ${
          this.token ? this.token : localStorage.getItem("token")
        }`,
      },
    });
    return next.handle(req).pipe(
      catchError((err) => {
        if (err.status === 401) {
          this.router.navigate(["/login"]); //go to login fail on 401
        }
        return observableThrowError(err);
      })
    );
  }
}
