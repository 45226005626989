import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dynamic-view',
  templateUrl: './dynamic-view.component.html',
  styleUrls: ['./dynamic-view.component.scss'],
})
export class DynamicViewComponent implements OnInit {
  @Input() data: any;
  @Input() buttons: { label: string, action: string, color?: string }[] = []; // Accept custom buttons
  @Output() buttonClicked = new EventEmitter<string>(); // Emit action when button is clicked
  @Output() objectDeleteClicked = new EventEmitter<string>(); // Emit action when button is clicked
  @Output() objectEditClicked = new EventEmitter<string>(); // Emit action when button is clicked
  apiUrlClean: any = environment.apiUrlClean;

  constructor() { }

  ngOnInit() {}
  getKeys(obj: any): string[] {
    return obj ? Object.keys(obj) : [];
  }

  isObject(value: any): boolean {
    return value !== null && typeof value === 'object' && !Array.isArray(value);
  }

  isArray(value: any): boolean {
    return Array.isArray(value);
  }
  onButtonClick(action: string) {
    this.buttonClicked.emit(action); 
  }
  openImagePreview(image) {
    window.open(this.apiUrlClean + image)
  }
  delete(key,data){
    this.objectDeleteClicked.emit(JSON.stringify({key:key,data:data}));
  }
  edit(key,data){
    this.objectEditClicked.emit(JSON.stringify({key:key,data:data}));
  }
  
}
