import { Component, Input, OnInit } from '@angular/core';
import { ToastService } from '../../services/toast.service';
import { ImportExportService } from '../../services/import-export.service';

@Component({
  selector: 'app-import-export',
  templateUrl: './import-export.component.html',
  styleUrls: ['./import-export.component.scss'],
})
export class ImportExportComponent implements OnInit {
  selectedFile: File;
  @Input() appLabel: string = '';
  @Input() modelName: string = '';
  
  constructor(
    private importExportService: ImportExportService,
    private toast: ToastService,

  ) { }

  ngOnInit() { }
  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      this.selectedFile = file;
    }
  }

  // Upload CSV file
  async uploadCsv() {
    if (!this.selectedFile) {
      this.toast.toastServices('Please select a CSV file.', "danger", "bottom");
      return;
    }

    this.importExportService.importCsv(this.appLabel, this.modelName, this.selectedFile)
      .subscribe({
        next: (res) => {
          this.toast.toastServices('CSV imported successfully!', "success", "bottom")
          this.selectedFile = null;
        },
        error: (err) => this.toast.toastServices(`Error: ${err.error?.message || 'Import failed'}`, "danger", "bottom")
      });
  }

  // Download CSV file
  async downloadCsv() {
    this.importExportService.exportCsv(this.appLabel, this.modelName).subscribe({
      next: (blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${this.modelName}.csv`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        this.toast.toastServices('CSV downloaded successfully!', "success", "bottom");
      },
      error: (err) => this.toast.toastServices(`Error: ${err.error?.message || 'Download failed'}`, "danger", "bottom")
    });
  }
}
