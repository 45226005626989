import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FloatingMenuComponent } from "../../components/floating-menu/floating-menu.component";
import { HeaderComponent } from "../../components/header/header.component";
import { BackButtonComponent } from "../../components/back-button/back-button.component";
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { StyleFormatterPipe } from "../../pipe/style-formatter.pipe";
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { DynamicViewComponent } from "../../components/dynamic-view/dynamic-view.component";
import { DynamicFormComponent } from "../../components/dynamic-form/dynamic-form.component";
import { DynamicFormFieldComponent } from "../../components/dynamic-form-field/dynamic-form-field.component";
import { ImportExportComponent } from "../../components/import-export/import-export.component";

@NgModule({
  declarations: [ImportExportComponent,DynamicFormFieldComponent, DynamicFormComponent, HeaderComponent, FloatingMenuComponent, BackButtonComponent, DynamicViewComponent, StyleFormatterPipe],
  exports: [ImportExportComponent,DynamicFormFieldComponent, DynamicFormComponent, HeaderComponent, FloatingMenuComponent, BackButtonComponent, DynamicViewComponent, StyleFormatterPipe],
  imports: [CommonModule, FormsModule,ReactiveFormsModule, SelectDropDownModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule { }
