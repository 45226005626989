import { AlertService } from '../../services/alert.service';
import { RouteService } from './../../services/route.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() title: String;
  @Input() returnUrl: String;

  constructor(
    public routerServices: RouteService,
    public alertService: AlertService,
  ) { }

  ngOnInit() { }

  logout() {
    this.alertService
      .alertConfirm(
        "Alert",
        "Do you want to logout !!!",
        "Yes",
        "No"
      )
      .then((res) => {
        if (res) {
          localStorage.clear()
          this.routerServices.routerFunction("login")
        }
      });

  }
}
