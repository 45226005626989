import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(
    private alertController: AlertController
  ) { }
  async alertConfirm(header, msg, btnTxt1, btnTxt2): Promise<boolean> {
    return new Promise((resolve) => {

      this.alertController.create({
        header: header,
        message: msg,
        buttons: [
          {
            text: btnTxt2,
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              resolve(false)
            }
          }, {
            text: btnTxt1,
            handler: () => {
              resolve(true)
            }
          }
        ]
      }).then(alert => {
        alert.present()
      });
    });
  }
}
