import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'styleFormatter'
})
export class StyleFormatterPipe implements PipeTransform {

  transform(style: any): string {
    return Object.entries(style)
      .map(([key, value]) => `${key}: ${value};`)
      .join(' ');
  }

}
