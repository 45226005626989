import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { AuthenticationService } from '../shared/services/authentication.service';
import { ToastService } from '../shared/services/toast.service';

// permissions

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  token: any;
  constructor(private authService: AuthenticationService,private router: Router,private toastService:ToastService) {}
  canActivate(route: ActivatedRouteSnapshot): boolean {
    // console.log(localStorage.getItem('token'));
    this.token = localStorage.getItem('token');
    const requiredPermission = route.data['permission'];
    if (!this.token) {
      this.router.navigateByUrl('/login');
      return false;
    }
    // else if (!this.authService.hasPermission(requiredPermission)) {
    //   this.toastService.toastServices("You don't have access to this page","danger","bottom")
    //   return false;
    // }
    return true;
  }
}
