import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ImportExportService {

  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  // Function to export CSV
  exportCsv(appLabel: string, modelName: string): Observable<Blob> {
    const url = `${this.apiUrl}/import-export/${appLabel}/${modelName}/`;
    return this.http.get(url, { responseType: 'blob' }); // Expecting file data (blob)
  }

  // Function to import CSV
  importCsv(appLabel: string, modelName: string, file: File): Observable<any> {
    const url = `${this.apiUrl}/import-export/${appLabel}/${modelName}/`;
    const formData = new FormData();
    formData.append('csv_file', file);

        return this.http.post(url, formData);
  }
}
