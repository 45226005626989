import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-dynamic-form-field',
  templateUrl: './dynamic-form-field.component.html',
  styleUrls: ['./dynamic-form-field.component.scss'],
})
export class DynamicFormFieldComponent implements OnInit {
  @Input() field: any;
  @Input() form: FormGroup;
  constructor() { }

  ngOnInit() {}
  
  onFileChange(event: any, fieldName: string, multiple: boolean) {
    const files = event.target.files;
    if (multiple) {
      this.form.controls[fieldName].setValue(files);
    } else {
      this.form.controls[fieldName].setValue(files.length > 0 ? files[0] : null);
    }
  }
  setChecked(field,event){
    this.form.controls[field].setValue(event?.detail?.checked)
  }
  setValue(field,event){
    this.form.controls[field].setValue(event?.detail?.value)
  }
}
