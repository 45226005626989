import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
})
export class DynamicFormComponent implements OnInit {
  @Input() formConfig: any[] = [];
  @Input() formData: any = {}; 
  @Output() formSubmit = new EventEmitter<any>();
  form: FormGroup;

  constructor(private fb: FormBuilder) { }

  ngOnInit() { 
    this.createForm();
  }

  createForm() {
    let group: any = {};
    this.formConfig.forEach(field => {
      group[field.name] = new FormControl(
        this.formData[field.name] || '', 
        field.required ? Validators.required : []
      );
    });
    this.form = this.fb.group(group);
  }
  submit() {
    const formData = new FormData();
    
    Object.keys(this.form.controls).forEach((key) => {
      const value = this.form.controls[key].value;
      
      if (value instanceof FileList) {
        // Append multiple files
        for (let i = 0; i < value.length; i++) {
          formData.append(key, value[i]);
        }
      } else if (value instanceof File) {
        // Append single file
        formData.append(key, value);
      } else {
        // Append other form values
        formData.append(key, value);
      }
    });
    if (this.formData && this.formData.id) {
      formData.append('id', this.formData.id);
    }
    this.formSubmit.emit(formData);
  }
}
