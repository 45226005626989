import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

interface User {
  id: number;
  username: string;
  role: string;
  permissions: Record<string, boolean>;
}

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private userSubject = new BehaviorSubject<User | null>(null);
  user$ = this.userSubject.asObservable();
  constructor(private http: HttpClient) { }

  login(credentials) {
    return this.http
      .post(`${environment.apiUrl}/login/`, {
        username: credentials.username,
        password: credentials.password,
      })
      .toPromise();
  }


  hasPermission(permission: string): boolean {
    const permissions_ = JSON.parse(localStorage.getItem('permissions'))
    return permissions_[permission] || false;
  }
  checkPermission(action:string,model: string){
    const permissions_ = JSON.parse(localStorage.getItem('permissions'))

    const available= permissions_.find(e=>e.codename === action+"_"+model)    
    if (available) {
      return true;
    }
    return false;
  }
}
